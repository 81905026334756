<!--
 * @Description: 
 * @Author: xiawenlong
 * @Date: 2021-04-26 11:07:17
 * @LastEditors: xiawenlong
 * @LastEditTime: 2022-01-11 10:31:36
-->
<template>
  <div class="tinymce-editor">
    <editor
      v-model="myValue"
      :width="width"
      :height="height"
      :init="init"
      :disabled="disabled"
      @onClick="onClick"
    >
    </editor>
  </div>
</template>
<script>
import { getFileUpload } from '@/api/college'
import to from 'await-to'
import tinymce from 'tinymce/tinymce'
import Editor from '@tinymce/tinymce-vue'
import 'tinymce/icons/default'
import 'tinymce/themes/silver'
// 编辑器插件plugins
// 更多插件参考：https://www.tiny.cloud/docs/plugins/
import 'tinymce/plugins/image' // 插入上传图片插件
import 'tinymce/plugins/media' // 插入视频插件
import 'tinymce/plugins/table' // 插入表格插件
import 'tinymce/plugins/lists' // 列表插件
import 'tinymce/plugins/wordcount' // 字数统计插件
import 'tinymce/plugins/fullscreen' // 全屏插件
export default {
  components: {
    Editor,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    // 基本路径，默认为空根目录，如果你的项目发布后的地址为目录形式，
    // 即abc.com/tinymce，baseUrl需要配置成tinymce，不然发布后资源会找不到
    baseUrl: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    width: {
      type: [Number, String],
      default: '',
    },
    height: {
      type: Number,
      default: 300,
    },
    plugins: {
      type: [String, Array],
      default: 'lists image media table wordcount fullscreen lineheight',
    },
    toolbar: {
      type: [String, Array],
      default:
        'undo redo |  formatselect | bold italic forecolor backcolor lineheight | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | lists image media table | removeformat | fullscreen',
    },
  },
  data() {
    return {
      init: {
        language_url: `${this.baseUrl}/tinymce/langs/zh_CN.js`,
        language: 'zh_CN',
        skin_url: `${this.baseUrl}/tinymce/skins/ui/oxide`,
        content_css: `${this.baseUrl}/tinymce/skins/content/default/content.css`,
        // skin_url: `${this.baseUrl}/tinymce/skins/ui/oxide-dark`, // 暗色系
        // content_css: `${this.baseUrl}/tinymce/skins/content/dark/content.css`, // 暗色系
        height: this.height,
        width: this.width,
        plugins: this.plugins,
        toolbar: this.toolbar,
        branding: false,
        menubar: false,
        // 此处为图片上传处理函数，这个直接用了base64的图片形式上传图片，
        // 如需ajax上传可参考https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_handler
        images_upload_handler: (blobInfo, success) => {
          // const img = 'data:image/jpeg;base64,' + blobInfo.base64()
          // success(img)
          this.getFileUploadData(blobInfo, success)
        },
      },
      myValue: this.value,
    }
  },
  watch: {
    value(newValue) {
      this.myValue = newValue
    },
    myValue(newValue) {
      this.$emit('input', newValue)
    },
  },
  mounted() {
    tinymce.init({})
  },
  methods: {
    // 添加相关的事件，可用的事件参照文档=> https://github.com/tinymce/tinymce-vue => All available events
    // 需要什么事件可以自己增加
    onClick(e) {
      this.$emit('onClick', e, tinymce)
    },
    // 可以添加一些自己的自定义事件，如清空内容
    clear() {
      this.myValue = ''
    },
    async getFileUploadData(blobInfo, success) {
      const params = new FormData()
      params.append('fileTypeCode', 'F001')
      params.append('file', blobInfo.blob())
      params.append('fileName', blobInfo.blob().name)
      const [res, err] = await to(getFileUpload(params))
      if (err) return this.$message({ type: 'error', message: err.msg })
      success(res.data)
    },
  },
}
</script>
